<template>
  <div>
    <Header back="返回" title="工程专利" index="首页" titleOne="科创模块" titleTwo="工程专利" beforeTitle="详情" />
    <div class="content">
      <h1>详情</h1>
      <ul class="flex_ul">
        <div class="block">
          <li>
            <span>企业名称</span>
            <p v-if="viewList.company_name">{{viewList.company_name}}</p>
            <p v-if="!viewList.company_name">-</p>
          </li>
          <li>
            <span>业务类型</span>
            <p v-if="!viewList.business_type">-</p>
            <p v-if="viewList.business_type == 1">新申请</p>
            <p v-if="viewList.business_type == 2">托管</p>
            <p v-if="viewList.business_type == 3">变更</p>
            <p v-if="viewList.business_type == 4">驳回重新提交</p>
            <p v-if="viewList.business_type == 5">非正常撤回</p>
          </li>
          <li>
            <span>专利名称</span>
            <p v-if="viewList.patent_name">{{viewList.patent_name}}</p>
            <p v-if="!viewList.patent_name">-</p>
          </li>
          <li>
            <span>专利类型</span>
            <p v-if="!viewList.patent_type">-</p>
            <p v-if="viewList.patent_type == 1">发明专利</p>
            <p v-if="viewList.patent_type == 2">实用新型</p>
            <p v-if="viewList.patent_type == 3">外观设计</p>
            <p v-if="viewList.patent_type == 4">植物新品种</p>
            <p v-if="viewList.patent_type == 5">国家新药</p>
            <p v-if="viewList.patent_type == 6">集成电路布图设计专有权</p>
            <p v-if="viewList.patent_type == 7">国家级农作物品种</p>
            <p v-if="viewList.patent_type == 8">国家一级中药保护品种</p>
            <p v-if="viewList.patent_type == 9">其中：国防专利</p>
          </li>
          <li>
            <span>专利类型（内部）</span>
            <p v-if="viewList.patent_type_internal">{{viewList.patent_type_internal}}</p>
            <p v-if="!viewList.patent_type_internal">-</p>
          </li>
          <li>
            <span>内部编号</span>
            <p v-if="viewList.internal_code">{{viewList.internal_code}}</p>
            <p v-if="!viewList.internal_code">-</p>
          </li>
          <li>
            <span>申请日期</span>
            <p v-if="viewList.app_date_status">{{viewList.app_date_status}}</p>
            <p v-if="!viewList.app_date_status">-</p>
          </li>
          <li>
            <span>申请号</span>
            <p v-if="viewList.app_code_status">{{viewList.app_code_status}}</p>
            <p v-if="!viewList.app_code_status">-</p>
          </li>
        </div>
        <div class="block">
          <li>
            <span>工程师</span>
            <p v-if="viewList.engineer">{{viewList.engineer}}</p>
            <p v-if="!viewList.engineer">-</p>
          </li>
          <li>
            <span>流程</span>
            <p>-</p>
          </li>
          <li>
            <span>专利状态</span>
            <p v-if="!viewList.status">-</p>
            <p v-if="viewList.status == 1">新申请</p>
            <p v-if="viewList.status == 2">审查中</p>
            <p v-if="viewList.status == 3">审查合格</p>
            <p v-if="viewList.status == 4">发明实审</p>
            <p v-if="viewList.status == 5">授权</p>
            <p v-if="viewList.status == 6">驳回</p>
            <p v-if="viewList.status == 7">撤回</p>
            <p v-if="viewList.status == 8">专利权维持</p>
            <p v-if="viewList.status == 9">专利权失效</p>
            <p v-if="viewList.status == 10">审查意见</p>
            <p v-if="viewList.status == 11">补正</p>
            <p v-if="viewList.status == 12">专利证书</p>
          </li>
          <li>
            <span>专利状态（内部）</span>
            <p v-if="viewList.patent_status_internal">{{viewList.patent_status_internal}}</p>
            <p v-if="!viewList.patent_status_internal">-</p>
          </li>
          <li>
            <span>专利补写</span>
            <p>-</p>
          </li>
          <li>
            <span>下证情况</span>
            <p v-if="viewList.certificate_date">{{viewList.certificate_date}}</p>
            <p v-if="!viewList.certificate_date">-</p>
          </li>
          <li>
            <span>证书分发情况</span>
            <p v-if="viewList.certificate_situation">{{viewList.certificate_situation}}</p>
            <p v-if="!viewList.certificate_situation">-</p>
          </li>
          <li>
            <span>专利补助</span>
            <p v-if="viewList.subsidy">{{viewList.subsidy}}</p>
            <p v-if="!viewList.subsidy">-</p>
          </li>
        </div>
        <li style="width:50%">
          <span>客户经理</span>
          <p v-if="viewList.custom_manager">{{viewList.custom_manager}}</p>
          <p v-if="!viewList.custom_manager">-</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      viewList: {}
    }
  },
  created () {
    this.getView()
  },
  methods: {
    getView () {
      this.axios.get('/api/patent/view_project_patent', { params: { id: this.$route.query.id } }).then((res) => {
        this.viewList = res.data
      })
    },
  }

}
</script>

<style scoped>
.block {
  width: 50%;
  display: inline-block;
}
.flex_ul li {
  display: flex;
  align-items: center;
}
.flex_ul li span {
  width: 20%;
}
.flex_ul li p {
  color: #909399;
}
</style>